<template>

  <Section :options="computedOptions.sectionOptions" v-if="isUserOrdersLoading" class="order-list">

    <div class="d-flex justify-content-center">
      <div class="spinner-grow m-5" role="status">
        <span class="sr-only" hidden>Loading...</span>
      </div>
    </div>

  </Section>
  
  <Section :options="computedOptions.sectionOptions" v-else-if="userOrders.length > 0" class="order-list" ref="orderList">

    <div v-for="(order, index) in userOrders" :key="index + '-order-recap'" class="recap" :data-orderid="order._id">

      <div class="product product-list" ref="product">
        
            <div class="product-desc p-2">
                <div class="product-title">
                    <h3>
                        {{ getLocalizedText(labels.reference, {params:[order._id]}) }}
                    </h3>
                    <div :class="'order-status ' + order.status.toLowerCase()">
                      {{ getLocalizedText(getStatusLabel(order.status)) }}
                    </div>
                    <!-- <h4>{{ "toto" }}</h4> -->
                </div>
                
                <Products v-if="computedOptions.type == 'shop'" :products="order.products" readonly></Products>
                <Rentals v-if="computedOptions.type == 'hospitality'" :options="{products: order.products, readonly:true}"></Rentals>
                
                <div class="buttons">
                  <router-link :to="order.path" event="" @click.native.prevent="loadAndRedirectToOrder(order, $event)" class="button button-3d retrieve ladda-button" data-style="zoom-in">
                    <i class="icon-line-open"></i>
                    {{ getLocalizedText(labels.viewDetailsButton) }}
                  </router-link>
                </div>
            </div>
        
      </div>

    </div>

  </Section>

  <Section :options="computedOptions.sectionOptions" v-else class="order-list">

      {{ getLocalizedText(labels.noOrder) }}

    </Section>

</template>

<style lang="scss">

.order-list .product-desc .product-title h3 {
  float:left;
}
.order-list .product-desc .product-title .order-status {
  float:right;

  &.payment {
    color:green;
  }
  &.cancelled {
    color:red;
  }
  &.to_validate {
    color:orange;
  }
}

/* We move buttons to the right */
.order-list .recap .buttons {
  justify-content: end;
}

/* We set shadow and margin for product list item */
.product-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.product-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
    .product-list .product-image {
        width: 100%!important;
        margin-bottom: 20px;
    }
    .product-list .grid-inner {
      flex-wrap: wrap;
    } 
}

/* We display the content next to the picture */
.product-list .product-desc {
    padding-top:10px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
}

@media (max-width: 767.98px) {
    .product-list .product-desc {
      padding-left:10px;
      width:100%;
    } 
}

/* We reduce description margin and ensure it takes possible space */
.product-list .product-desc div.description {
      flex-grow: 1;
      margin-bottom: 10px;
}

.product-list .product-desc div.description ul {
    margin-left:30px;
}

/* We hide the product description on mobile device */
@media (max-width: 767.98px) {
    .product-list .product-desc div.description {
        /* display:none; */
    }
}

/* We set flex and correct wrapping to full buttons bar and add to cart section */
.product-list .buttons,
.product-list .buttons .add-to-cart {
    display:flex;
    flex-wrap: wrap;
}

/* We set margins for details button and add to cart sectino */
.product-list .buttons .view-details,
.product-list .buttons .add-to-cart {
    margin:0px 30px 10px 0px;
}
  
</style>


<script lang="ts">
import { useOrder } from '@root/src/client/composables/useOrder'


import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';
import { hospitalityTimelineDefaultLabels, shopTimelineDefaultLabels } from '../../../panels/shop/order/Timeline.vue';


/** @cmsOptions */
export interface UserOrdersOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
  /** @cmsType CmsEnum */
  type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface UserOrdersLabels {
  /** @cmsType CmsLabel */
  viewDetailsButton?:CmsLabel;
  /** @cmsType CmsLabel */
  reference?:CmsLabel;
  /** @cmsType CmsLabel */
  noOrder?:CmsLabel;
}

export const shopDefaultLabels:UserOrdersLabels = {
  viewDetailsButton : {
    fr : "Afficher",
    de : "Anzeigen",
    en : "View Details"
  },
  reference : {
    fr : "Référence: {0}",
    de : "Referenz: {0}",
    en : "Reference: {0}"
  },
  noOrder : {
    fr : "Aucune commande trouvée",
    de : "Keine Bestellung gefunden",
    en : "No order found"
  }
}

export const hospitalityDefaultLabels:UserOrdersLabels = {
  viewDetailsButton : {
    fr : "Afficher",
    de : "Anzeigen",
    en : "View Details"
  },
  reference : {
    fr : "Référence: {0}",
    de : "Referenz: {0}",
    en : "Reference: {0}"
  },
  noOrder : {
    fr : "Aucune réservation trouvée",
    de : "Keine Reservierung gefunden",
    en : "No booking found"
  }
}

/** @cmsSlots */
export interface UserOrdersSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<UserOrdersOptions>,
      labels: {
        type: Object as PropType<UserOrdersLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<UserOrdersSlots>
  },
  components: {
    Products : () => import(/* webpackChunkName: "panels-checkout-cart-products" */ '@root/src/client/components/panels/shop/container/Products.vue'),
    Rentals : () => import(/* webpackChunkName: "panels-checkout-cart-rentals" */ '@root/src/client/components/panels/hospitality/container/Rentals.vue'),
    Section
  },
  setup(props, context) {
    const app = getApp();
    const store = useStore();

    const computedOptions:UserOrdersOptions = {
      sectionOptions : {
        section:false,
        contentWrap:true,
        container:true
      },
      type : "shop",
      ...props.options,
    };

    const { getUserList, getOrderPath, redirectToOrderPage, retrieveOrder } = useOrder({type:computedOptions.type!}, context);

    const labels:UserOrdersLabels = {
        ...((computedOptions.type == "shop") ? shopDefaultLabels : hospitalityDefaultLabels),
        ...props.labels
    }

    const currentLanguageCode =  store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

    const orderList:Ref<any|null> = ref(null);

    const userOrders:Ref<any[]> = ref([]);
    const isUserOrdersLoading:Ref<boolean> = ref(true);
    getUserList().then((orders:any[]) => {
      userOrders.value = orders;
      // We add the path to order details
      userOrders.value.forEach(async (order:any) => {
        order.path = await getOrderPath(order._id);
      })
      isUserOrdersLoading.value = false;
    })

    const loadAndRedirectToOrder = async (order:any, evt:Event) => {
      evt.preventDefault();

      var laddaSubmit:null|Ladda.LaddaButton = null;
      if(orderList.value) {
        var submitButton:HTMLButtonElement|null = orderList.value.$el.querySelector('.recap[data-orderid="'+order._id+'"] a.retrieve.ladda-button' );
        if(submitButton){
          laddaSubmit = Ladda.create(submitButton!);
        }
      }     

      if(laddaSubmit) { laddaSubmit!.start(); }
      retrieveOrder(order._id).then((result:any) => {
        if(result.order) {
          redirectToOrderPage(result.order._id);
        }
        else {
          if(laddaSubmit) { laddaSubmit!.stop(); }
        }
      });
    }

    const getStatusLabel = (status:any):string => {
      return computedOptions.type == "shop" ? shopTimelineDefaultLabels[status.toLowerCase()+'.active'] : hospitalityTimelineDefaultLabels[status.toLowerCase()+'.active'];
    }

    return {
        labels,
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        userOrders,
        isUserOrdersLoading,
        loadAndRedirectToOrder,
        orderList,
        getStatusLabel
    }

  }
})
</script>